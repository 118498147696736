import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DietarySupplementService {
  constructor(private http: HttpClient) {}

  load(productFilter: any): Observable<any> {
    const { page, ...filter } = productFilter;
    return this.http.get<any>(
      `${environment.primaryApiUrl}/dietary_supplement`,
      {
        params: {
          page,
          filter: JSON.stringify(filter),
          general: 0,
        },
      },
    );
  }

  loadCategories() {
    return this.http.get<any>(
      `${environment.primaryApiUrl}/dict/dietary_supplement_category`,
    );
  }

  collectRecipes(checkedProductsIds: number[]): Observable<any> {
    return this.http.post<any>(
      `${environment.primaryApiUrl}/recipes/selection`,
      {
        product_ids: checkedProductsIds,
      },
    );
  }

  compareProducts(checkedProductsIds: number[]): Observable<any> {
    return this.http.post<any>(
      `${environment.primaryApiUrl}/dietary_supplement/comparison`,
      {
        data: {
          ids: checkedProductsIds,
        },
      },
    );
  }

  toggleProductToLocked(productsId: number): Observable<any> {
    return this.http.put<any>(
      `${environment.primaryApiUrl}/dietary_supplement/${productsId}/locked`,
      {},
    );
  }

  loadProductNutrientFilters(): Observable<any> {
    return this.http.get<any>(`${environment.primaryApiUrl}/products/filters`);
  }

  loadProductGroups(): Observable<any> {
    return this.http.get<any>(`${environment.primaryApiUrl}/dict/category`);
  }
}
